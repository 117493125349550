/* Custom Fonts */
@font-face {
    font-family: 'ProtoMono';
    src: url('/public/font/ProtoMono/ProtoMono-Regular.woff2');
  }
  
/* Reset.CSS */
html,
body {
  padding: 0;
  margin: 0;
  font-family: ProtoMono!important;
  background-color:   #080404;
  color: white;
}
.card{
  background: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
